@import url("https://fonts.googleapis.com/css2?family=Signika&display=swap");

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.f {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}
.t-l {
  padding: 10px 40px 10px 40px;
  font-size: 14pt;
  font-weight: 100;
}

.App-link {
  color: #61dafb;
}
.title {
  text-align: center;
  font-size: 24pt;
  font-weight: 500pt;
  margin-bottom: 5vh;
}
.exp-detail {
  margin-left: 3%;
  margin-top: -1%;
}
.exp-detail > li {
  margin-left: 3%;
  font-size: 12pt;
}
.exp-detail-title {
  margin-right: 2%;
  font-weight: bold;
  margin-bottom: 10px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.metode {
  color: rgb(37, 189, 82);
  display: flex;
  align-items: center;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #eaeaea;
}

.hidden {
  display: none !important;
}
